import React from 'react'
import { TbCheck, TbX } from 'react-icons/tb'

export const TableCell = ({ value, logo, isTitle }) => {
  return typeof value === 'boolean' ? (
    <span className='table__cell'>
      <span className='table__icon'>
        {value ? <TbCheck className='icon' /> : <TbX className='icon' />}
      </span>
    </span>
  ) : (
    <>
      {logo ? (
        <span className='table__cell'>
          <img src={logo.src} alt={value} className='table__logo' />
        </span>
      ) : (
        <>
          {isTitle ? (
            <div
              className='table__cell table__cell--title'
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ) : (
            <span className='table__cell'>{value}</span>
          )}
        </>
      )}
    </>
  )
}
