import React, { forwardRef, Ref, InputHTMLAttributes } from 'react'
import { TbSearch } from 'react-icons/tb'
import clsx from 'clsx'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
  placeholder?: string
  hasIcon?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { hasIcon, className, inputClassName, ...props },
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <div
        className={clsx(
          'input-block',
          hasIcon && 'input-block--icon',
          className,
        )}
      >
        <div className='input-block__content'>
          <input
            className={clsx('input-block__element', inputClassName)}
            {...props}
            ref={ref}
          />
          {hasIcon && <TbSearch className='icon' />}
        </div>
      </div>
    )
  },
)
