import { PropsWithChildren } from 'react'
import Slugger from 'github-slugger'
import { TbChevronDown } from 'react-icons/tb'
import { Markdown } from 'routes/components/Shared'

type AccordionItem = PropsWithChildren<{
  title: string
}>

export const Accordion = ({
  array,
}: {
  array: Array<Record<'title' | 'content', string>>
}) => {
  return (
    <AccordionWrapper>
      {array.map((item, index) => {
        return (
          <AccordionItem title={item.title} key={index}>
            <Markdown>{item.content}</Markdown>
          </AccordionItem>
        )
      })}
    </AccordionWrapper>
  )
}

export function AccordionWrapper({ children }) {
  return (
    <div className='accordion-block'>
      <ul className='accordion-block__items'>{children}</ul>
    </div>
  )
}

export function AccordionItem({ title, children }: AccordionItem) {
  const slugger = new Slugger()
  const index = slugger.slug(title)
  return (
    <li className='accordion-block__item'>
      <input
        className='accordion-block__item-checkbox'
        id={`check-${index}`}
        type='checkbox'
      />

      <label className='accordion-block__item-title' htmlFor={`check-${index}`}>
        {title}
        <TbChevronDown className='accordion-block__item-icon' />
      </label>

      <div className='accordion-block__item-content'>{children}</div>
    </li>
  )
}
